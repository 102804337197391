<template>
  <base-card>
    <v-card-title>
      <div class="d-flex justify-space-between align-end" style="width: 100%;">
        <v-btn
          v-if="isAdminUser"
          color="primary"
          @click="onCreateManualOffer"
        >
          <v-icon>mdi-send</v-icon>
          {{ $t("manualOffer.sendManualOffer") }}
        </v-btn>
        <v-spacer v-else />
        <div class="d-flex align-end">
          <v-select
            v-if="!isUser"
            v-model="selectedUserId"
            :items="userList"
            :label="$t('manualOffer.userName')"
            hide-details
            item-text="name"
            item-value="id"
            clearable
            class="mr-10"
          />

          <v-select
            v-model="selectedStatus"
            :items="statusList"
            :label="$t('common.status')"
            hide-details
            attach
            multiple
            clearable
            item-text="text"
            item-value="value"
            class="mr-5"
          />
        </div>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="manualOfferList"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2"
      >
        <template v-slot:item.description="{item}">
          <div v-html="util.convertTextToHtml(item.description)" />
        </template>
        <template v-slot:item.status="{item}">
          <v-chip
            v-if="item.status === 'pending'"
            class="px-4"
            color="orange darken-4 white--text"
          >
            <v-icon left>
              mdi-account-clock
            </v-icon>
            {{ $t('manualOffer.pending') }}
          </v-chip>
          <v-chip
            v-else-if="item.status === 'accept'"
            class="px-4"
            color="primary"
          >
            <v-icon left>
              mdi-checkbox-marked-circle
            </v-icon>
            {{
              $t('manualOffer.acceptedOn', {
                date: util.formatDateTime(item.modifiedDate, 'MMM DD YYYY')
              })
            }}
          </v-chip>
          <template v-else>
            <v-chip
              class="px-4"
              color="error"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{
                $t('manualOffer.rejectedOn', {
                  date: util.formatDateTime(item.modifiedDate, 'MMM DD YYYY')
                })
              }}
            </v-chip>
          </template>
        </template>
        <template v-slot:item.action="{item}">
          <template v-if="isAdminUser && item.status === 'pending'">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  small
                  color="error"
                  @click.native="onDeleteManualOffer(item)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("common.delete") }}</span>
            </v-tooltip>
          </template>

          <template v-if="(isUser || isPartnerAdminUser && item.userId === currentUser.id) && item.status === 'pending'">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  small
                  color="primary"
                  @click.native="onAcceptManualOffer(item)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("manualOffer.accept") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  small
                  color="error"
                  @click.native="onRejectManualOffer(item)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("manualOffer.reject") }}</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <send-manual-offer-dialog
      v-model="isShowSendManualOfferDialog"
      :user-list="userList"
      @send-manual-offer="sendManualOffer"
    />
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'
  import Swal from 'sweetalert2'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
    showQuestionDialog,
  } from '@/utils/swalDialog'

  import SendManualOfferDialog from './SendManualOfferDialog'

  export default {
    metaInfo: {
      title: 'Manual Offers',
    },

    components: {
      SendManualOfferDialog,
    },

    data () {
      return {
        statusList: [{
          text: this.$t('manualOffer.pending'),
          value: 'pending',
        }, {
          text: this.$t('manualOffer.accepted'),
          value: 'accept',
        }, {
          text: this.$t('manualOffer.rejected'),
          value: 'reject',
        }],
        userList: [],
        manualOfferList: [],
        selectedUserId: '',
        selectedStatus: [],
        headers: [],
        isShowSendManualOfferDialog: false,
        limit: 10,
        currentPage: 1,
        totalPages: 0,
        util: util,
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
        currentUser: 'getCurrentUser',
      }),

      isUser() {
        return !this.isAdminUser && !this.isPartnerAdminUser
      },
    },

    watch: {
      selectedUserId() {
        this.loadManualOffers()
      },

      selectedStatus(value) {
        this.loadManualOffers()
      },

      currentPage(val) {
        this.loadManualOffers(val)
      }
    },

    async mounted() {
      if (this.isAdminUser) {
        this.headers = [
          { value: 'index', text: this.$t('common.number') },
          { value: 'userName', text: this.$t('manualOffer.userName') },
          { value: 'companyName', text: this.$t('partner.companyName') },
          { value: 'subject', text: this.$t('manualOffer.subject') },
          { value: 'description', text: this.$t('common.description'), cellClass: 'py-2' },
          { value: 'status', text: this.$t('common.status') },
          { text: 'Action', value: 'action', width: 100 },
        ]
      } else {
        this.headers = [
          { value: 'index', text: this.$t('common.number') },
          { value: 'userName', text: this.$t('manualOffer.userName') },
          { value: 'subject', text: this.$t('manualOffer.subject') },
          { value: 'description', text: this.$t('common.description') },
          { value: 'status', text: this.$t('common.status') },
          { text: 'Action', value: 'action', width: 150 },
        ]
      }

      if (this.isAdminUser || this.isPartnerAdminUser) {
        await this.loadUsers()
      }

      const grandidsession = this.$route.query.grandidsession || ''
      if (grandidsession) {
        this.accept(grandidsession)
      } else {
        await this.loadManualOffers()
      }
    },

    methods: {
      ...mapActions({
        fetchUsers: 'fetchUsers',
        createManualOffer: 'createManualOffer',
        fetchManualOfferList: 'fetchManualOfferList',
        signWithBankIdForManualOffer: 'signWithBankIdForManualOffer',
        acceptManualOffer: 'acceptManualOffer',
        rejectManualOffer: 'rejectManualOffer',
        deleteManualOffer: 'deleteManualOffer',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadUsers() {
        const pagination = {
          offset: 0,
          limit: 0,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchUsers(pagination)

          this.userList = response.rows.map(user => {
            return {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`
            }
          })
          this.userList = _.orderBy(this.userList, ['name'])
        } catch (error) {
          this.userList = []
        }
        this.setLoading(false)
      },

      async loadManualOffers(currentPage = 1) {
        this.currentPage = currentPage
        const pagination = {
          offset: (this.currentPage - 1) * this.limit,
          limit: this.limit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchManualOfferList({
            userId: this.selectedUserId,
            status: this.selectedStatus.join(','),
            ...pagination,
          })

          this.totalPages = Math.ceil(response.count / this.limit)
          this.manualOfferList = response.rows.map((item, index) => {
            return {
              ...item,
              index: (this.currentPage - 1) * this.limit + index + 1,
              companyName: item.user?.partner?.companyName || '',
              userName: item.user?.name || '',
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      onCreateManualOffer() {
        this.isShowSendManualOfferDialog = true
      },

      async sendManualOffer(data) {
        this.isShowSendManualOfferDialog = false

        this.setLoadingText(`${this.$t('manualOffer.sendingManualOffer')}...`)
        this.setLoading(true)
        try {
          await this.createManualOffer(data)

          showSuccessDialog(this.$t('manualOffer.sentManualOfferSuccessfully'))
          await this.loadManualOffers()
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      },

      async onDeleteManualOffer(row) {
        const btnResponse = await showQuestionDialog(
          this.$t('manualOffer.deleteManualOffer'),
          this.$t('manualOffer.areYouSureToDeleteManualOffer')
        )

        if (!btnResponse.value) {
          return
        }

        this.setLoadingText(`${this.$t('manualOffer.deletingManualOffer')}...`)
        this.setLoading(true)
        try {
          await this.deleteManualOffer(row.id)

          showSuccessDialog(this.$t('manualOffer.deletedManualOfferSuccessfully'))
          await this.loadManualOffers()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async onAcceptManualOffer(row) {
        const btnResponse = await Swal.fire({
          text: this.$t('order.pleaseInputPersonalNumber'),
          input: 'text',
          icon: 'question',
          inputPlaceholder: this.$t('order.pleaseInputPersonalNumber'),
          inputValidator: (value) => {
            return value ? null : this.$t('order.pleaseInputPersonalNumber')
          },
          validationMessage: this.$t('order.pleaseInputPersonalNumber'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.no'),
        })

        if (!btnResponse.value) {
          return
        }

        let redirectUrl = ''

        this.setLoadingText(`${this.$t('manualOffer.signingWithBankId', {
          bankId: btnResponse.value,
        })}...`)
        this.setLoading(true)
        try {
          const response = await this.signWithBankIdForManualOffer({
            id: row.id,
            personalNumber: btnResponse.value,
            offerInfo: this.$t('manualOffer.youAreAcceptingManualOffer', {
              subject: row.subject,
            }),
          })

          redirectUrl = response.redirectUrl
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)

        if (redirectUrl) {
          localStorage.setItem('manualOfferId', row.id)
          location.href = redirectUrl
        }
      },

      async accept(sessionId) {
        const manualOfferId = localStorage.getItem('manualOfferId')

        if (!manualOfferId) {
          this.loadManualOffers()

          return
        }

        this.setLoadingText(`${this.$t('manualOffer.acceptingManualOffer')}...`)
        this.setLoading(true)
        try {
          await this.acceptManualOffer({
            id: manualOfferId,
            sessionId,
          })

          showSuccessDialog(this.$t('manualOffer.acceptManualOfferSuccessfully'))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)

        this.loadManualOffers()
        localStorage.removeItem('manualOfferId')
      },

      async onRejectManualOffer(row) {
        const btnResponse = await Swal.fire({
          title: this.$t('manualOffer.pleaseInputRejectReason'),
          input: 'text',
          icon: 'question',
          inputPlaceholder: this.$t('manualOffer.rejectReason'),
          inputValidator: (value) => {
            return value ? null : this.$t('manualOffer.pleaseInputRejectReason')
          },
          validationMessage: this.$t('manualOffer.pleaseInputRejectReason'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('manualOffer.reject'),
          cancelButtonText: this.$t('common.no'),
        })

        if (!btnResponse.value) {
          return
        }

        this.setLoadingText(`${this.$t('manualOffer.rejectingManualOffer')}...`)
        this.setLoading(true)
        try {
          await this.rejectManualOffer({
            id: row.id,
            rejectReason: btnResponse.value,
          })

          showSuccessDialog(this.$t('manualOffer.rejectManualOfferSuccessfully'))

          await this.loadManualOffers(this.currentPage)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },
    },
  }
</script>
